<template>
  <v-card style="min-height:inherit" elevation="0" :style="
          'padding-bottom: ' + this.$root.tabspadding + 'px;'
        " >
    <v-card-title class="text-primary">
      {{ $t("APPOINTMENTS.add.selectDoc") }}
    </v-card-title>
    <div class="row m-0" style="padding-bottom:60px">
      <div v-if="$root.mobile">
        <v-list three-line class="bg-transparent">
          <div
            v-for="doctor in doctorsList"
            :key="doctor.id"
            class="mb-2 listitem"
          >
            <v-list-item
              style="min-height:80px"
              @click="
                !docinfo ? (doctortoshow = doctor) : (doctortoshow = null)
              "
            >
              <v-list-item-avatar size="47" color="primary">
                <v-img
                  v-if="doctor.doctor_id.avatar != null"
                  :src="doctor.doctor_id.avatar.data.thumbnails[0].url"
                ></v-img>
                <div
                  v-else
                  style="text-transform: uppercase;width:100%"
                  class="white--text font-size-h4"
                >
                  {{
                    doctor.doctor_id.first_name.charAt(0) +
                      doctor.doctor_id.last_name.charAt(0)
                  }}
                </div>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <div class="listitemtitle">
                    {{ doctor.doctor_id.first_name }}
                    {{ doctor.doctor_id.last_name }}
                  </div>
                </v-list-item-title>

                <v-list-item-subtitle class="listitemsubtitle">
                  <div style="display:flex;" class="text-primary">
                    <div id="videoprice" class="" style="height: 20px;display: flex;align-items: center;flex-direction: row;">
                      <unicon
                              :class="$root.lang != 'ar' ? 'pr-1' : 'pl-1'"
                              name="video"
                              fill="#0971CE"
                              height="20"
                            ></unicon>
                            
                      <span
                        v-if="!isPaymentEnabled() || doctor.video == null"
                        >{{ $t("APPOINTMENTS.add.free") }}</span
                      >
                      <span v-else
                        >{{ (doctor.video * $root.exchangerate).toFixed(2)
                        }}<span class="currency">{{
                          getLocalCurrency()
                        }}</span></span
                      >
                    </div>
                    <div id="clinicprice" class="px-2" style="height: 20px;display: flex;align-items: center;flex-direction: row;">
                            <unicon
                            :class="$root.lang != 'ar' ? 'pr-1' : 'pl-1'"
                              name="hospital"
                              fill="#0971CE"
                              height="14"
                            ></unicon>
  
                      <span
                        v-if="
                          isPaymentEnabled() == false || doctor.clinic == null
                        "
                        >{{ $t("APPOINTMENTS.add.free") }}</span
                      >
                      <span v-else
                        >{{ (doctor.clinic * $root.exchangerate).toFixed(2)
                        }}<span class="currency">{{
                          getLocalCurrency()
                        }}</span></span
                      >
                    </div>
                  </div>
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="doctor.languages_spoken.length > 0" class="listitemsubtitle mt-1">
                  <div style="display:flex; align-items: center;">
                    {{ $t("APPOINTMENTS.add.speaks") }}
                    <div v-for="lang in doctor.languages_spoken" :key="lang.id">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-avatar class="mx-1" v-bind="attrs" v-on="on" size="20"
                            ><v-img
                              :src="getFlag(lang.languages_id.id)"
                              width="25"
                              height="25"
                              contain
                          /></v-avatar>
                        </template>
                        <span>{{ lang.languages_id.name }} </span>
                      </v-tooltip>
                    </div>
                  </div>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  small
                  fab
                  elevation="0"
                  color="rgba(165, 203, 239, 0.20)"
                  @click="
                    docinfo = true;
                    showdocinfo(doctor);
                  "
                >
                  <v-icon color="primary">mdi-information-outline</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </div>
        </v-list>
        <v-bottom-sheet v-model="apptypesheet" >
          <v-card
            class="rounded-lg"
            style="border-bottom-left-radius: 0 !important;border-bottom-right-radius: 0 !important;"
            :style="'padding-bottom: ' + $root.tabspadding + 'px;'"
          >
            <v-toolbar flat dark color="white" class="text-primary">
              <v-toolbar-title>{{ $t("APPOINTMENTS.add.apptype") }}</v-toolbar-title>
              <v-spacer />
              <v-btn icon dark color="primary" @click="apptypesheet = false; doctortoshow = null">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>

            <div class="p-3">
              <v-list v-if="doctortoshow">
                <v-list-item
                  @click="
                    doSelectDoctor(doctortoshow, 'video');
                    apptypesheet = false;
                  "
                >
                  <v-list-item-icon>
                    <unicon
                              style="margin-right: auto;margin-left: auto;"
                              name="video"
                              fill="#0971CE"
                              height="20"
                            ></unicon>
                           
                  </v-list-item-icon>
                  <v-list-item-title
                    >{{ $t("APPOINTMENTS.APP.type_d.video") }}
                  </v-list-item-title>
                  <v-list-item-action>
                    <span
                      v-if="
                        isPaymentEnabled() == false ||
                          doctortoshow.video == null
                      "
                      >{{ $t("APPOINTMENTS.add.free") }}</span
                    >
                    <span v-else
                      >{{ (doctortoshow.video * $root.exchangerate).toFixed(2)
                      }}<span class="currency">{{ getLocalCurrency() }}</span></span
                    >
                  </v-list-item-action>
                </v-list-item>
                <v-list-item
                  @click="
                    doSelectDoctor(doctortoshow, 'clinic');
                    apptypesheet = false;
                  "
                >
                  <v-list-item-icon>

                            <unicon
                              style="margin-right: auto;margin-left: auto;"
                              name="hospital"
                              fill="#0971CE"
                              height="20"
                            ></unicon>
                  </v-list-item-icon>
                  <v-list-item-title
                    >{{ $t("APPOINTMENTS.APP.type_d.clinic") }}
                  </v-list-item-title>
                  <v-list-item-action>
                    <span
                      v-if="
                        isPaymentEnabled() == false ||
                          doctortoshow.clinic == null
                      "
                      >{{ $t("APPOINTMENTS.add.free") }}</span
                    >
                    <span v-else
                      >{{ (doctortoshow.clinic * $root.exchangerate).toFixed(2)
                      }}<span class="currency">{{ getLocalCurrency() }}</span></span
                    >
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </div>
          </v-card>
        </v-bottom-sheet>
        
      </div>
      <div
        v-else
        v-for="doctor in doctorsList"
        :key="doctor.id"
        class="col-xl-4 col-lg-4 col-md-4 col-6 p-2"
      >
        <div
          class="bg-white bg-hover-gray-200 text-hover-white rounded-lg text-center"
          style="min-height: 250px;box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;"
        >
          <div style="position: relative; float: right">
            <v-btn
              @click="showdocinfo(doctor)"
              text
              icon
              color="primary lighten-2"
            >
              <v-icon>mdi-information-outline</v-icon>
            </v-btn>
          </div>
          <div
            style="position: absolute;top: 20%;left: 0;width: 100%;margin: auto;"
          >
            <div class="mb-3">
              <v-avatar v-if="doctor.doctor_id.avatar" size="70">
                <v-img
                  alt="loading"
                  :src="doctor.doctor_id.avatar.data.thumbnails[2].url"
                />
              </v-avatar>
              <v-avatar color="primary" v-else size="70">
                <span
                  class="font-size-h5 font-weight-bold text-white"
                  style="text-transform: uppercase"
                  >{{
                    doctor.doctor_id.first_name.charAt(0) +
                      doctor.doctor_id.last_name.charAt(0)
                  }}</span
                >
              </v-avatar>
            </div>
            <span class="font-size-h4 text-primary"
              >{{ $t("APPOINTMENTS.add.dr") }}
              {{ doctor.doctor_id.first_name }}
              {{ doctor.doctor_id.last_name }}</span
            >
            <div
              style="width: 100%"
              class="row m-0 mt-2 pr-5 pl-5 text-center text-primary"
            >
              <div id="videoprice" class="col-6 p-1" style="">
                <em class="fas fa-video mr-1 ml-2"></em>
                <span v-if="!isPaymentEnabled() || doctor.video == null">{{
                  $t("APPOINTMENTS.add.free")
                }}</span>
                <span v-else
                  >{{ (doctor.video * $root.exchangerate).toFixed(2)
                  }}<span class="currency">{{ getLocalCurrency() }}</span></span
                >
              </div>
              <div id="clinicprice" class="col-6 p-1" style="">
                <em class="fas fa-user-friends mr-1 ml-2"></em>
                <span
                  v-if="isPaymentEnabled() == false || doctor.clinic == null"
                  >{{ $t("APPOINTMENTS.add.free") }}</span
                >
                <span v-else
                  >{{ (doctor.clinic * $root.exchangerate).toFixed(2)
                  }}<span class="currency">{{ getLocalCurrency() }}</span></span
                >
              </div>
            </div>
            <div style="width: 100%" class="mt-2 pr-5 pl-5 text-center">
              <v-menu v-if="!$root.mobile" rounded offset-y :key="doctor.id">
                <template v-slot:activator="{ attrs, on }">
                  <v-btn color="primary" block v-bind="attrs" v-on="on">
                    book</v-btn
                  >
                </template>

                <v-list>
                  <v-list-item @click="doSelectDoctor(doctor, 'video')">
                    <v-list-item-icon>
                      <v-icon small color="primary">fas fa-video</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title
                      >{{ $t("APPOINTMENTS.APP.type_d.video") }}
                    </v-list-item-title>
                    <v-list-item-action>
                      <span
                        v-if="
                          isPaymentEnabled() == false || doctor.video == null
                        "
                        >{{ $t("APPOINTMENTS.add.free") }}</span
                      >
                      <span v-else
                        >{{ (doctor.video * $root.exchangerate).toFixed(2)
                        }}<span class="currency">{{
                          getLocalCurrency()
                        }}</span></span
                      >
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item @click="doSelectDoctor(doctor, 'clinic')">
                    <v-list-item-icon>
                      <v-icon small color="primary">fas fa-user-friends</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title
                      >{{ $t("APPOINTMENTS.APP.type_d.clinic") }}
                    </v-list-item-title>
                    <v-list-item-action>
                      <span
                        v-if="
                          isPaymentEnabled() == false || doctor.clinic == null
                        "
                        >{{ $t("APPOINTMENTS.add.free") }}</span
                      >
                      <span v-else
                        >{{ (doctor.clinic * $root.exchangerate).toFixed(2)
                        }}<span class="currency">{{
                          getLocalCurrency()
                        }}</span></span
                      >
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="display:flex;justify-content: center;padding: 8px;" class="actionbar">
      <v-btn
        large
        width="45%"
        rounded
        elevation="2"
        color="blue darken-1"
        text
        class="mx-1"
        @click="gotostep(1)"
      >
        {{ $t("APPOINTMENTS.add.back") }}
      </v-btn>
      <v-btn class="mx-1" large width="45%" color="red" dark rounded @click="doclose()">
        {{ $t("APPOINTMENTS.add.cancel") }}
      </v-btn>
    </div>
    <v-dialog v-model="docinfo" persistent max-width="350">
      <v-card v-if="selecteddoctoshow">
        <v-card-title class="headline">
          <v-avatar v-if="selecteddoctoshow.doctor_id.avatar" size="50">
            <v-img
              alt="loading"
              :src="selecteddoctoshow.doctor_id.avatar.data.thumbnails[2].url"
            />
          </v-avatar>
          <v-avatar color="primary" v-else size="50">
            <span
              class="font-size-h5 font-weight-bold text-white"
              style="text-transform: uppercase"
              >{{
                selecteddoctoshow.doctor_id.first_name.charAt(0) +
                  selecteddoctoshow.doctor_id.last_name.charAt(0)
              }}</span
            >
          </v-avatar>
          <span class="mx-2"
            >{{ selecteddoctoshow.doctor_id.first_name }}
            {{ selecteddoctoshow.doctor_id.last_name }}</span
          >
        </v-card-title>
        <v-card-text>{{ selecteddoctoshow.bio }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="docinfo = false">
            {{$t("POPUPS.okay")}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
//import ApiService from "@/core/services/api.service";

export default {
  name: "DoctorList",
  props: ["doctorsList"],
  data() {
    return {
      docinfo: false,
      selecteddoctoshow: null,
      doctortoshow: {},
      apptypesheet: false,
      languages: {},
    };
  },
  watch: {
    doctortoshow(newdoc) {
      if (newdoc) {
        if (!this.docinfo) this.apptypesheet = true;
      }
    },
  },
  created() {
    this.loadlangs();
  },
  mounted() {},
  methods: {
    getFlag(id) {
      for (var i = 0; i < this.languages.length; i++) {
        if (this.languages[i].id == id)
          return (
            this.$root.assetsBaseURL + this.languages[i].flag.data.asset_url
          );
      }
    },
    
    isPaymentEnabled() {
      return JSON.parse(localStorage.SETTINGS).payment;
    },
    getLocalCurrency()
    {
      return JSON.parse(localStorage.geoData).currencySymbolNative;
    },
    getCurrency() {
      return JSON.parse(localStorage.SETTINGS).currency;
    },
    doSelectDoctor(doctor, apptype) {
      this.$emit("doctorSelected", { doc: doctor, type: apptype });
    },
    gotostep(e) {
      this.$emit("changeStep", e);
    },
    doclose() {
      this.$emit("doclose", true);
    },
    showdocinfo(doc) {
      this.selecteddoctoshow = doc;
      this.docinfo = true;
    },
    async loadlangs() {
      await this.$api
        .get("items", "languages?fields=id,name,flag.data")
        .then(({ data }) => {
          console.log("languages list ", data.data);
          this.languages = data.data;
        })
        .catch((error) => {
          this.error = error;
        });
    },
  },
};
</script>
