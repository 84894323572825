<template>
  <v-card
    v-if="doctor"
    style="min-height:inherit"
    elevation="0"
    :style="'padding-bottom: ' + this.$root.tabspadding + 'px;'"
  >
    <div
      v-if="isloadingtimes"
      class="text-center mt-5"
      style="width: 100%; margin-right: auto; margin-left: auto"
    >
      <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
      <div>{{ $t("APPOINTMENTS.add.gettingavail") }}</div>
    </div>
    <div v-else-if="!$root.mobile" style="display:flex;flex-direction:row">
      <div class="" style="
    width: 45%;
">
        <v-date-picker
          :min="new Date().toISOString().substr(0, 10)"
          v-model="selectedDate"
          :allowed-dates="(val) => allowedDates.indexOf(val) !== -1"
          @update:picker-date="monthChange"
          :disabled="disableCalendar"
          @click:date="dateChange"
          :locale="this.$root.lang"
          :full-width="$root.mobile"
        >
          <v-progress-linear
            indeterminate
            style="position: absolute;top: 88px;padding: 0px !important;right: 0;left: 0;"
            v-if="disableCalendar"
          ></v-progress-linear
        ></v-date-picker>
      </div>
      <div
          class="mt-2"
          style="width:55%; display: flex;flex-direction: column;align-items: center;height: calc((97vh - 120px) - 0px); overflow:hidden;"
        >

          <v-progress-circular
            indeterminate
            color="primary"
            v-if="loadingTimes"
            class="mt-5"
          ></v-progress-circular>
          <div
            v-if="allowedSlots.length == 0 && !loadingTimes"
            style="font-size: 1rem;"
            class="mt-5"
          >
            {{ $t("APPOINTMENTS.add.noavail") }}
          </div>
          <div
            style="width: 100%;
    overflow-y: scroll;"
            v-if="allowedSlots.length > 0 && !loadingTimes"
          >
            <v-card-title class="text-primary">
              {{ $t("APPOINTMENTS.add.avail") }}
            </v-card-title>
            <div
              class="mb-2 timerow"
              style="width:100%"
              v-for="(row, index) in groupedslots"
              :key="index"
            >
              <div class="timeheader bg-primary">
                {{
                  (selectedDate + "T" + row[0].slot + "Z")
                    | moment("timezone", getTimeZone(), "hh A")
                }}
              </div>
              <div class="p-3" style=" display:flex; flex-direction:row">
                <div
                  class="p-0 my-0 text-primary timeslot"
                  :class="$root.lang == 'ar' ? 'ml-1' : 'mr-1'"
                  v-for="slot in row"
                  :key="slot.index"
                  @click="doSetSelectedTime(slot.index)"
                >
                  {{
                    (selectedDate + "T" + slot.slot + "Z")
                      | moment("timezone", getTimeZone(), "hh:mm")
                  }}
                </div>
              </div>
            </div>
          </div>

        </div>
    </div>
    <div
      style="display:flex; flex-direction: column;padding-bottom:60px"
      v-else
    >
      <div>
        <v-text-field
          background-color="rgba(165, 203, 239, 0.42)"
          prepend-inner-icon="mdi-calendar"
          color="primary"
          hide-details=""
          height="60"
          rounded
          readonly
          filled
          v-model="selectedDate"
          @click="opendateselection = true"
        />

        <div
          class="mt-2 px-1"
          style="display: flex;flex-direction: column;align-items: center;min-height: 70vh;"
        >
          <v-progress-circular
            indeterminate
            color="primary"
            v-if="loadingTimes"
            class="mt-5"
          ></v-progress-circular>
          <div
            v-if="allowedSlots.length == 0 && !loadingTimes"
            style="font-size: 1rem;"
            class="mt-5"
          >
            {{ $t("APPOINTMENTS.add.noavail") }}
          </div>
          <div
            style="width:97%"
            v-if="allowedSlots.length > 0 && !loadingTimes"
          >
            <v-card-title class="text-primary">
              {{ $t("APPOINTMENTS.add.avail") }}
            </v-card-title>
            <div
              class="mb-2 timerow"
              style=""
              v-for="(row, index) in groupedslots"
              :key="index"
            >
              <div class="timeheader bg-primary">
                {{
                  (selectedDate + "T" + row[0].slot + "Z")
                    | moment("timezone", getTimeZone(), "hh A")
                }}
              </div>
              <div class="p-3" style=" display:flex; flex-direction:row">
                <div
                  class="p-0 my-0 text-primary timeslot"
                  :class="$root.lang == 'ar' ? 'ml-1' : 'mr-1'"
                  v-for="slot in row"
                  :key="slot.index"
                  @click="doSetSelectedTime(slot.index)"
                >
                  {{
                    (selectedDate + "T" + slot.slot + "Z")
                      | moment("timezone", getTimeZone(), "hh:mm")
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
         <v-bottom-sheet  v-model="opendateselection">
          <v-card elevation="0">
            <v-progress-linear
              indeterminate
              v-if="disableCalendar"
            ></v-progress-linear>
            <v-toolbar>
              <v-toolbar-title>{{ $t("APPOINTMENTS.add.selectDate") }}</v-toolbar-title>
              <v-spacer />
              <v-btn
                icon
                dark
                :ripple="false"
                color="primary"
                @click="opendateselection = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-date-picker
              :min="new Date().toISOString().substr(0, 10)"
              v-model="selectedDate"
              :allowed-dates="(val) => allowedDates.indexOf(val) !== -1"
              @update:picker-date="monthChange"
              :disabled="disableCalendar"
              @click:date="dateChange"
              :locale="this.$root.lang"
              :full-width="$root.mobile"
              no-title
            >
            </v-date-picker>
          </v-card>
        </v-bottom-sheet>
      </div>
    </div>
    <v-card-actions
      style="display:flex;justify-content: center;padding: 8px;"
      class="actionbar"
    >
      <v-btn
        large
        width="45%"
        rounded
        elevation="2"
        color="blue darken-1"
        text
        class="mx-1"
        @click="gotostep(2)"
      >
        {{ $t("APPOINTMENTS.add.back") }}
      </v-btn>
      <v-btn
        class="mx-1"
        large
        width="45%"
        color="red"
        dark
        rounded
        @click="doclose()"
      >
        {{ $t("APPOINTMENTS.add.cancel") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
  name: "DateTimeSelect",
  props: ["doctor"],
  data() {
    return {
      today: new Date().toISOString().substr(0, 10),
      availableTimes: [],
      monthCalendar: [],
      disableCalendar: false,
      loadingTimes: false,
      isloadingtimes: true,
      selectedDate: new Date().toISOString().substr(0, 10),
      selectedTime: null,
      groupedslots: [],
      opendateselection: false,
    };
  },
  computed: {
    allowedDates() {
      var toReturn = [];
      this.monthCalendar.filter((i) => {
        if (i.all > 0 && i.available > 0) toReturn.push(i.date);
      });
      return toReturn;
    },
    allowedSlots() {
      this.$moment.locale("en")
      //to ensure that we remove any timeslot that is in the next 30 mins
      var plusvalue =
        30 -
        (this.$moment()
          .add(30, "m")
          .minute() %
          30);
      var currenttime = this.$moment().add(plusvalue, "m");

      //console.log("current time", currenttime.format("DD/MM/YYYY hh:mm A"));
      var toReturn = [];
      this.availableTimes.filter((i) => {
        /*console.log("slot is", this.$moment(this.selectedDate + " " + i + " Z").tz(this.getTimeZone()).format("DD/MM/YYYY hh:mm A"), " current time",currenttime.format("DD/MM/YYYY hh:mm A") 
        ,"condition is", this.$moment(this.selectedDate + " " + i + " Z").tz(this.getTimeZone()).format("X") - currenttime.format("X"));*/
        
        if (
          this.$moment(this.selectedDate + " " + i + " Z")
            .tz(this.getTimeZone())
            .format("X") -
            currenttime.format("X") >
          -100
        )
        {
          toReturn.push(i);

        }
      });
      this.$moment.locale(this.$root.lang)
      return toReturn;
    },
  },
  watch: {
    allowedSlots(newslots) {
      this.groupedslots = [];
      if (newslots.length > 0) {
        var row = 0;
        var arr = [];
        for (var i = 0; i < newslots.length - 1; i++) {
          if (newslots[i].split(":")[0] == newslots[i + 1].split(":")[0]) {
            //console.log("if row ", row, "value: ", newslots[i]);
            arr.push({ index: i, slot: newslots[i] });
          } else {
            //console.log("else row ", row, "value: ", newslots[i]);
            arr.push({ index: i, slot: newslots[i] });
            this.groupedslots.push(arr);
            row++;
            arr = [];
          }
        }
        if (this.groupedslots.length == row) this.groupedslots.push(arr);
        if (
          typeof(newslots[newslots.length - 1]) != undefined && newslots[newslots.length - 1].split(":")[0] ==
          newslots[newslots.length - 2].split(":")[0]
        ) {
          console.log("row ", row, "value: ", newslots[newslots.length-1]);
          this.groupedslots[this.groupedslots.length - 1].push({
            index: newslots.length - 1,
            slot: newslots[newslots.length - 1],
          });
        } else {
          arr = [];
          arr.push({
            index: newslots.length - 1,
            slot: newslots[newslots.length - 1],
          });
          this.groupedslots.push(arr);
        }
      }
      //console.log("grouped slots", this.groupedslots);
    },
  },
  mounted() {
    this.getAvailability();
  },
  methods: {
    async getAvailability() {
      this.$moment.locale("en");
      await this.getDoctorAvailability(
        this.doctor.id,
        this.$moment().format("/YYYY/MM")
      )
        .then(({ data }) => {
          //console.log("availability data", data.data);
          //this.departments[index]["doctors"] = data.data;
          this.monthCalendar = data.data;
          this.getAvailableTimes(this.doctor.id, this.today);
          //console.log("allowed dates are", this.allowedDates);
          this.isloadingtimes = false;
          this.$moment.locale(localStorage.language);
        })
        .catch((error) => {
          this.error = error;
        });
    },
    async getDoctorAvailability(id, month) {
      return ApiService.get("custom", "docavail/cal/" + id + month);
    },

    async monthChange(month) {
      this.$moment.locale("en");
      this.disableCalendar = true;
      await this.getDoctorAvailability(
        this.doctor.id,
        this.$moment(month).format("/YYYY/MM")
      )
        .then(({ data }) => {
          //console.log("availability data", data.data);
          //this.departments[index]["doctors"] = data.data;
          this.monthCalendar = data.data;
          //console.log("month cal is", this.monthCalendar);
          this.disableCalendar = false;
          this.$moment.locale(localStorage.language);
        })
        .catch((error) => {
          this.error = error;
        });
    },
    async getAvailableTimes(id, day) {
      this.loadingTimes = true;
      this.availableTimes = [];
      await ApiService.get("custom", "docavail/" + id + "/" + day)
        .then(({ data }) => {
          this.loadingTimes = false;
          if (data.error) {
            this.error = data.error.error;
            this.availableTimes = [];
          } else {
            //console.log("available times", data.data);
            this.availableTimes = data.data;
          }
        })
        .catch((error) => {
          this.error = error;
        });
    },
    async dateChange(date) {
      if (this.opendateselection) this.opendateselection = false;
      //console.log("date is: ", date);
      this.selectedDate = date;
      this.getAvailableTimes(this.doctor.id, date);
    },
    getTimeZone() {
      return localStorage.timezone;
    },
    isPaymentEnabled() {
      return JSON.parse(localStorage.SETTINGS).payment;
    },
    getDuration() {
      return JSON.parse(localStorage.SETTINGS).call_duration;
    },
    doSetSelectedTime(timetosend) {
      this.$emit("timeSelected", {
        time: this.allowedSlots[timetosend],
        date: this.selectedDate,
      });
    },
    gotostep(e) {
      this.$emit("changeStep", e);
    },
    doclose() {
      this.$emit("doclose", true);
    },
    showdocinfo(doc) {
      this.selecteddoctoshow = doc;
      this.docinfo = true;
    },
  },
};
</script>
