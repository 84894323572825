<template>
  <v-card
    :disabled="departments == null"
    min-height="100%"
    elevation="0"
    :style="'padding-bottom: ' + this.$root.tabspadding + 'px;'"
  >
    <v-card-title class="text-primary">
      {{ $t("APPOINTMENTS.add.selectDeprt") }} 
    </v-card-title>
    <v-card-text v-if="departments != null && doctorsloaded == departments.length" class="row grid m-0 p-0">
      <template v-for="depart in departments">
        <div
          v-if="depart.doctors && depart.doctors.length > 0"
          :key="depart.id"
          class="col-xl-3 col-lg-3 col-md-3 col-6"
          style="padding:5px;"
        >
          <div class="griditem bg-hover-gray-200 text-hover-white text-center">
            <div style="width: 100%;">
              <v-btn
                style="margin-right: 7px;margin-top: 7px;position: absolute;top: 0;right: 0;"
                @click="
                  departinfo = !departinfo;
                  selectedDepartmentTitle = getTranslation(depart).name;
                  selectedDepartmentDescription = getTranslation(depart)
                    .description;
                "
                text
                icon
                color="primary lighten-2"
              >
                <v-icon>mdi-information-outline</v-icon>
              </v-btn>
            </div>

            <div class="clicktarget" @click="doSelect(depart)">
              <div v-if="depart.avatar != null" class="griditemicon">
                <img
                  width="25"
                  height="25"
                  alt="Pic"
                  :src="$root.assetsBaseURL + '' + depart.avatar.data.asset_url"
                />
              </div>
              <v-avatar v-else x-large>
                <span
                  class="font-size-h5 font-weight-bold"
                  style="text-transform: uppercase"
                  >{{ depart.name.charAt(0) }}</span
                >
              </v-avatar>
              <span class="mt-3 griditemtitle">{{
                getTranslation(depart).name
              }}</span>
              <div style="width: 100%" class="mt-2 text-center">
                <span class="font-weight-bold text-primary">
                  {{ depart.doctors.length }}
                  {{ $t("APPOINTMENTS.add.doctors") }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </template>
    </v-card-text>
    <v-card-actions class="actionbar">
      <v-spacer></v-spacer>
      <v-btn block large rounded color="red darken-1" dark @click="doclose()">
        {{ $t("APPOINTMENTS.add.cancel") }}
      </v-btn>
    </v-card-actions>
    <v-dialog v-model="departinfo" persistent max-width="350" min-height="500">
      <v-card>
        <v-card-title class="headline">
          {{ selectedDepartmentTitle }}
        </v-card-title>
        <v-card-text>{{ selectedDepartmentDescription }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="departinfo = false">
            {{$t("POPUPS.okay")}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
  name: "DepartmentList",
  props: [],
  data() {
    return {
      departments: null,
      departmentsLoading: false,
      departinfo: false,
      selectedDepartmentTitle: "",
      selectedDepartmentDescription: "",
      doctorsloaded:0
    };
  },
  mounted() {
    this.getDepartments();
  },
  methods: {
    async getDepartments() {
      if (!this.departmentsLoading) {
        await ApiService.get(
          "items",
          "department?fields=id,name,avatar.data,description,translation.*"
        )
          .then(({ data }) => {
            //console.log("department list ", data.data);
            this.departments = data.data;

            
          })
          .catch((error) => {
            this.error = error;
          });

         this.departments.forEach( async d => {
          //console.log("getting doctors for ", d);
          await this.$api
            .get(
              "items",
              "doctor?fields=id,doctor_id.*.*,bio,social,department,video,clinic,practice_started, languages_spoken.languages_id.*.*&filter[department]=" +
                d.id +
                "&filter[doctor_id.status]=active"
            )
            .then(({ data }) => {
              d.doctors = data.data;
              this.doctorsloaded++
            })
            .catch((error) => {
              this.error = error;
            });
        }); 
        //console.log("departmens:",this.departments)
        this.departmentsLoading = false;
      }
    },
    doSelect(e) {
      this.$emit("departmentSelected", e);
    },
    doclose() {
      this.$emit("doclose", true);
    },
    getTranslation(d) {
      //console.log(d);
      if (d.translation.length > 0) {
        for (var i = 0; i < d.translation.length; i++) {
          if (d.translation[i].lang == this.$root.lang) {
            return d.translation[i];
          }
        }
      } else {
        return d;
      }
    },
  },
};
</script>
<style scoped>
.clicktarget {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
}

.departmentdoctorcount {
  text-align: center;
}
</style>
