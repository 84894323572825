<template>
  <v-card :disabled="bookingStarted" min-height="100%">
    <v-system-bar
      dark
      color="primary"
      :height="$root.sysbarheight"
      v-if="$root.showBar"
    >
    </v-system-bar>
    <v-toolbar flat dark color="primary">
      <v-btn icon dark @click="backHandler()">
        <v-icon v-if="!$root.mobile && e6 == 1">mdi-close</v-icon>
        <v-icon v-else>{{
          $root.lang == "en" ? "mdi-chevron-left" : "mdi-chevron-right"
        }}</v-icon>
      </v-btn>
      <v-toolbar-title
        >{{ $t("APPOINTMENTS.add.newApp") }} - {{ steptitle }}</v-toolbar-title
      >
    </v-toolbar>
    <v-card-text
      :style="
        'min-height: calc(97vh - 56px - ' +
          $root.sysbarheight +
          'px);background-color: white;'
      "
      class="p-0 m-0"
    >
      <v-stepper
        v-model="e6"
        class="elevation-0 p-3 m-0"
        style="min-height:inherit;"
      >
        <v-stepper-header v-if="false">
          <v-stepper-step :complete="e6 > 1" step="1">
            {{ $t("APPOINTMENTS.add.selectDeprt") }}
            <small v-if="selectedDepartment != null">{{
              selectedDepartment.name
            }}</small>
          </v-stepper-step>

          <img
            v-if="this.$root.lang == 'ar'"
            src="/media/svg/icons/Navigation/Angle-left.svg"
          />
          <img v-else src="/media/svg/icons/Navigation/Angle-right.svg" />

          <v-stepper-step :complete="e6 > 2" step="2">
            {{ $t("APPOINTMENTS.add.selectDoc") }}
            <small v-if="selectedDoctor != null"
              >{{ selectedDoctor.doctor_id.first_name }}
              {{ selectedDoctor.doctor_id.last_name }}</small
            >
          </v-stepper-step>

          <img
            v-if="this.$root.lang == 'ar'"
            src="/media/svg/icons/Navigation/Angle-left.svg"
          />
          <img v-else src="/media/svg/icons/Navigation/Angle-right.svg" />

          <v-stepper-step :complete="e6 > 3" step="3">
            {{ $t("APPOINTMENTS.add.selectTime") }}
            <small
              >{{ selectedDate }}
              <span v-if="selectedTime">{{
                (selectedDate + " " + selectedTime + " Z")
                  | moment("timezone", getTimeZone(), "hh:mm A")
              }}</span></small
            >
          </v-stepper-step>
          <img
            v-if="this.$root.lang == 'ar'"
            src="/media/svg/icons/Navigation/Angle-left.svg"
          />
          <img v-else src="/media/svg/icons/Navigation/Angle-right.svg" />
          <v-stepper-step step="4">{{
            $t("APPOINTMENTS.add.pay")
          }}</v-stepper-step>
        </v-stepper-header>

        <v-stepper-items style="min-height:inherit">
          <v-stepper-content
            step="1"
            class="p-0 m-0"
            style="min-height:inherit;"
          >
            <DepartmentList
              @doclose="close()"
              @departmentSelected="selectDepartment"
            />
          </v-stepper-content>

          <v-stepper-content
            step="2"
            class="p-0 m-0"
            style="min-height:inherit;"
          >
            <DoctorList
              v-if="e6 == 2"
              :doctorsList="selectedDepartment.doctors"
              @doclose="close()"
              @doctorSelected="selectDoctor"
              @changeStep="navigatetostep"
            />
          </v-stepper-content>

          <v-stepper-content
            step="3"
            class="p-0 m-0"
            style="min-height:inherit;"
          >
            <DateTimeSelect
              v-if="e6 == 3 && selectedDoctor"
              :doctor="selectedDoctor"
              @timeSelected="setSelectedTime"
              @doclose="close()"
              @changeStep="navigatetostep"
            />
          </v-stepper-content>

          <v-stepper-content
            step="4"
            class="p-0 m-0"
            style="min-height:inherit;"
          >
            <v-card elevation="0">
              <div v-if="!$root.mobile">
                <div style="display:flex;">
                  <div style="width:45%">
                    <v-card-title class="text-primary">
                      {{ $t("APPOINTMENTS.add.details") }}
                    </v-card-title>
                    <div
                      class="mb-2 listitem"
                      style="min-height:70px;box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;    width: 96%;margin-right: auto;margin-left: auto;"
                    >
                      <v-expand-transition>
                        <div>
                          <div>
                            <!-------- item 1 ----------->
                            <v-list-item v-if="selectedDepartment">
                              <v-list-item-avatar size="40px" color="#f2f7fd">
                                <v-img
                                  width="20"
                                  height="20"
                                  v-if="selectedDepartment.avatar != null"
                                  contain
                                  :src="
                                    $root.assetsBaseURL +
                                      '' +
                                      selectedDepartment.avatar.data.asset_url
                                  "
                                />
                                <template v-else>
                                  <span
                                    class="font-size-h5 font-weight-bold"
                                    style="text-transform: uppercase"
                                    >{{
                                      selectedDepartment.name.charAt(0)
                                    }}</span
                                  >
                                </template>
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <div class="cardtitle">
                                  {{ getTranslation(selectedDepartment).name }}
                                </div>
                              </v-list-item-content>
                            </v-list-item>
                            <!-------- item 2 ----------->
                            <v-list-item v-if="selectedDoctor">
                              <v-list-item-avatar size="40px" color="#f2f7fd">
                                <v-img
                                  v-if="selectedDoctor.doctor_id.avatar"
                                  :src="
                                    selectedDoctor.doctor_id.avatar.data
                                      .thumbnails[0].url
                                  "
                                  contain
                                />
                                <template v-else>
                                  <span
                                    class="text-primary font-size-h5 font-weight-bold"
                                    style="text-transform: uppercase"
                                    >{{
                                      selectedDoctor.doctor_id.first_name.charAt(
                                        0
                                      )
                                    }}</span
                                  >
                                </template>
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <div class="cardtitle">
                                  {{ selectedDoctor.doctor_id.first_name }}
                                  {{ selectedDoctor.doctor_id.last_name }}
                                </div>
                              </v-list-item-content>
                            </v-list-item>
                            <!-------- item 3 ----------->
                            <v-list-item v-if="appointementType">
                              <v-list-item-avatar size="40px" color="#f2f7fd">
                                <unicon
                                  style="margin-right: auto;margin-left: auto;"
                                  name="video"
                                  v-if="appointementType == 'video'"
                                  fill="#0971CE"
                                  height="20"
                                ></unicon>
                                <unicon
                                  style="margin-right: auto;margin-left: auto;"
                                  name="user-arrows"
                                  v-if="appointementType == 'clinic'"
                                  fill="#0971CE"
                                  height="20"
                                ></unicon>
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <div class="cardtitle">
                                  <span v-if="appointementType == 'video'">{{
                                    $t("APPOINTMENTS.APP.type_d.video")
                                  }}</span>
                                  <span v-if="appointementType == 'clinic'">{{
                                    $t("APPOINTMENTS.APP.type_d.clinic")
                                  }}</span>
                                </div>
                              </v-list-item-content>
                            </v-list-item>
                            <!-------- item 4 ----------->
                            <v-list-item
                              v-if="
                                selectedDate != null && selectedTime != null
                              "
                            >
                              <v-list-item-avatar size="40px" color="#f2f7fd">
                                <unicon
                                  style="margin-right:auto; margin-left:auto; height:20px"
                                  name="calender"
                                  fill="#0971CE"
                                ></unicon>
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <div class="cardtitle">
                                  {{ selectedDate }}
                                  {{
                                    (selectedDate + "T" + selectedTime + "Z")
                                      | moment(
                                        "timezone",
                                        getTimeZone(),
                                        "hh:mm A"
                                      )
                                  }}
                                </div>
                              </v-list-item-content>
                            </v-list-item>
                            <!-------- item 5 ----------->
                          </div>
                        </div>
                      </v-expand-transition>
                    </div>
                    <v-list-item
                      v-if="selectedDate != null && selectedTime != null"
                    >
                      <v-list-item-avatar size="40px" color="#f2f7fd">
                        <unicon
                          style="margin-right:auto; margin-left:auto; height:20px"
                          name="dollar-alt"
                          fill="#0971CE"
                        ></unicon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <span class="font-size-sm"
                          >{{ $t("APPOINTMENTS.add.price") }}
                          {{ getDoctorFees() }} {{ getLocalCurrency() }}</span
                        >
                        <span v-if="getTax() != ''" class="font-size-sm"
                          >{{ $t("APPOINTMENTS.add.tax") }}
                          <span v-if="getDoctorFees() == 0">
                            0 {{ getLocalCurrency() }}</span
                          >
                          <span v-else>
                            {{ (getTax() / 100) * getDoctorFees() }}
                            {{ getLocalCurrency() }}</span
                          >
                        </span>
                        <span v-if="getFee() != ''" class="font-size-sm"
                          >{{ $t("APPOINTMENTS.add.fees") }}
                          <span>{{ getFee() }} {{ getLocalCurrency() }}</span>
                        </span>
                      </v-list-item-content>
                      <v-list-item-action
                        class="text-primary font-weight-boldest font-size-h5 line-height-sm"
                      >
                        {{ totalAmount() }}
                        {{ getLocalCurrency() }}
                      </v-list-item-action>
                    </v-list-item>
                  </div>
                  <div style="flex-grow: 1;">
                    <v-card-title class="text-primary mb-0 pb-0">
                      {{ $t("APPOINTMENTS.add.pymtOp") }}
                    </v-card-title>
                    <div v-if="!isPaymentEnabled()">
                      <div
                        class="text-primary"
                        style="display:flex;text-align: center;padding: 10px;font-size: 1.2rem;"
                      >
                        {{ $t("APPOINTMENTS.add.freemsg") }}
                      </div>
                    </div>
                    <v-card-text class="px-4" v-else>
                      <div v-if="paymentMethods">
                        <v-checkbox v-model="concent" color="primary">
                          <template v-slot:label
                            ><div class="mt-2 font-size-sm text-muted">
                              {{ $t("APPOINTMENTS.add.concent") }}
                            </div></template
                          ></v-checkbox
                        >
                        <div
                          v-if="allowedPaymentMethods.length > 0"
                          style="display:flex; flex-wrap: wrap; width:100%"
                        >
                          <v-list-item
                            v-if="selectedPaymentMethod"
                            class="rounded-lg mb-1"
                          >
                            <v-list-item-avatar size="30" tile>
                              <v-icon color="primary" size="30">
                                {{ selectedPaymentMethod.icon }}
                              </v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title
                                style="font-size:1.1rem"
                                class="text-primary"
                              >
                                {{ getTranslation(selectedPaymentMethod).name }}
                              </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-btn icon @click="unselectpayment"
                                ><v-icon color="red">mdi-close</v-icon></v-btn
                              >
                            </v-list-item-action>
                          </v-list-item>
                          <template v-else>
                            <div
                              v-for="(item, index) in allowedPaymentMethods"
                              :key="item.code"
                              style="display:flex-box;     flex-direction: column;"
                            >
                              <v-list-item
                                @click="openpaymentwidget(item)"
                                class="rounded-lg mb-1"
                              >
                                <v-list-item-avatar size="30" tile>
                                  <v-icon color="primary" size="30">
                                    {{ item.icon }}
                                  </v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                  <v-list-item-title
                                    style="font-size:1.1rem"
                                    class="text-primary"
                                  >
                                    {{ getTranslation(item).name }}
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-divider
                                class="m-0 p-0"
                                style="display:block"
                                height="1px"
                                width="100%"
                                :key="'d' + index"
                                v-if="index < allowedPaymentMethods.length - 1"
                              />
                            </div>
                          </template>
                        </div>
                        <template v-if="allowedPaymentMethods.length > 0">
                          <PaymentWidget
                            :paymentuid="banktransferudid"
                            :isActive="displaypayment"
                            :payment_method="selectedPaymentMethod"
                            :total="totalAmount()"
                            :currency="getLocalCurrency()"
                            @close="displaypayment = false"
                            @paymentsuccess="postransaction"
                          />
                        </template>
                      </div>
                    </v-card-text>
                  </div>
                </div>
              </div>
              <div v-else style="padding-bottom:130px">
                <template>
                  <v-card-title class="text-primary">
                    {{ $t("APPOINTMENTS.add.details") }}
                  </v-card-title>
                  <div
                    class="mb-2 listitem"
                    style="min-height:70px;box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;    width: 96%;margin-right: auto;margin-left: auto;"
                  >
                    <v-list-item
                      style="min-height:70px"
                      @click="showdetails = !showdetails"
                    >
                      <div
                        style="flex-grow: 1;flex-shrink: 1;flex-basis: 0%;display:flex; flex-direction:row"
                      >
                        <div class="pushx" v-if="selectedDepartment">
                          <div
                            class="griditemicon"
                            style="width:40px; height:40px;"
                            v-if="selectedDepartment.avatar != null"
                          >
                            <img
                              width="20"
                              height="20"
                              alt="Pic"
                              :src="
                                $root.assetsBaseURL +
                                  '' +
                                  selectedDepartment.avatar.data.asset_url
                              "
                            />
                          </div>
                          <v-avatar v-else>
                            <span
                              class="font-size-h5 font-weight-bold"
                              style="text-transform: uppercase"
                              >{{ selectedDepartment.name.charAt(0) }}</span
                            >
                          </v-avatar>
                        </div>
                        <div class="pushx" v-if="selectedDoctor">
                          <v-avatar color="#f2f7fd" size="40px">
                            <v-img
                              v-if="selectedDoctor.doctor_id.avatar"
                              :src="
                                selectedDoctor.doctor_id.avatar.data
                                  .thumbnails[0].url
                              "
                              contain
                            />
                            <span
                              v-else
                              class="text-primary font-size-h5 font-weight-bold"
                              style=" text-transform: uppercase"
                            >
                              {{
                                selectedDoctor.doctor_id.first_name.charAt(0)
                              }}
                            </span>
                          </v-avatar>
                        </div>
                        <div class="pushx" v-if="appointementType">
                          <div
                            class="griditemicon"
                            style="width:40px; height:40px;"
                          >
                            <unicon
                              style="margin-right: auto;margin-left: auto;"
                              name="video"
                              v-if="appointementType == 'video'"
                              fill="#0971CE"
                              height="20"
                            ></unicon>
                            <unicon
                              style="margin-right: auto;margin-left: auto;"
                              name="user-arrows"
                              v-if="appointementType == 'clinic'"
                              fill="#0971CE"
                              height="20"
                            ></unicon>
                          </div>
                        </div>

                        <div
                          class="pushx"
                          v-if="selectedDate != null && selectedTime != null"
                        >
                          <div
                            class="griditemicon text-primary"
                            style="width:auto; min-width:60px; height:40px; padding:0; padding-right:10px; padding-left:10px"
                          >
                            {{ selectedDate }}
                            {{
                              (selectedDate + "T" + selectedTime + "Z")
                                | moment("timezone", getTimeZone(), "hh:mm A")
                            }}
                          </div>
                        </div>
                      </div>
                      <v-list-item-action>
                        <v-btn icon>
                          <v-icon color="primary">{{
                            showdetails ? "mdi-chevron-up" : "mdi-chevron-down"
                          }}</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                    <v-expand-transition>
                      <div v-show="showdetails">
                        <div>
                          <!-------- item 1 ----------->
                          <v-list-item v-if="selectedDepartment">
                            <v-list-item-avatar size="40px" color="#f2f7fd">
                              <v-img
                                width="20"
                                height="20"
                                v-if="selectedDepartment.avatar != null"
                                contain
                                :src="
                                  $root.assetsBaseURL +
                                    '' +
                                    selectedDepartment.avatar.data.asset_url
                                "
                              />
                              <template v-else>
                                <span
                                  class="font-size-h5 font-weight-bold"
                                  style="text-transform: uppercase"
                                  >{{ selectedDepartment.name.charAt(0) }}</span
                                >
                              </template>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <div class="cardtitle">
                                {{ getTranslation(selectedDepartment).name }}
                              </div>
                            </v-list-item-content>
                          </v-list-item>
                          <!-------- item 2 ----------->
                          <v-list-item v-if="selectedDoctor">
                            <v-list-item-avatar size="40px" color="#f2f7fd">
                              <v-img
                                v-if="selectedDoctor.doctor_id.avatar"
                                :src="
                                  selectedDoctor.doctor_id.avatar.data
                                    .thumbnails[0].url
                                "
                                contain
                              />
                              <template v-else>
                                <span
                                  class="text-primary font-size-h5 font-weight-bold"
                                  style="text-transform: uppercase"
                                  >{{
                                    selectedDoctor.doctor_id.first_name.charAt(
                                      0
                                    )
                                  }}</span
                                >
                              </template>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <div class="cardtitle">
                                {{ selectedDoctor.doctor_id.first_name }}
                                {{ selectedDoctor.doctor_id.last_name }}
                              </div>
                            </v-list-item-content>
                          </v-list-item>
                          <!-------- item 3 ----------->
                          <v-list-item v-if="appointementType">
                            <v-list-item-avatar size="40px" color="#f2f7fd">
                              <unicon
                                style="margin-right: auto;margin-left: auto;"
                                name="video"
                                v-if="appointementType == 'video'"
                                fill="#0971CE"
                                height="20"
                              ></unicon>
                              <unicon
                                style="margin-right: auto;margin-left: auto;"
                                name="user-arrows"
                                v-if="appointementType == 'clinic'"
                                fill="#0971CE"
                                height="20"
                              ></unicon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <div class="cardtitle">
                                <span v-if="appointementType == 'video'">{{
                                  $t("APPOINTMENTS.APP.type_d.video")
                                }}</span>
                                <span v-if="appointementType == 'clinic'">{{
                                  $t("APPOINTMENTS.APP.type_d.clinic")
                                }}</span>
                              </div>
                            </v-list-item-content>
                          </v-list-item>
                          <!-------- item 4 ----------->
                          <v-list-item
                            v-if="selectedDate != null && selectedTime != null"
                          >
                            <v-list-item-avatar size="40px" color="#f2f7fd">
                              <unicon
                                style="margin-right:auto; margin-left:auto; height:20px"
                                name="calender"
                                fill="#0971CE"
                              ></unicon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <div class="cardtitle">
                                {{ selectedDate }}
                                {{
                                  (selectedDate + "T" + selectedTime + "Z")
                                    | moment(
                                      "timezone",
                                      getTimeZone(),
                                      "hh:mm A"
                                    )
                                }}
                              </div>
                            </v-list-item-content>
                          </v-list-item>
                          <!-------- item 5 ----------->
                        </div>
                      </div>
                    </v-expand-transition>
                  </div>
                  <v-list-item
                    v-if="selectedDate != null && selectedTime != null"
                  >
                    <v-list-item-avatar size="40px" color="#f2f7fd">
                      <unicon
                        style="margin-right:auto; margin-left:auto; height:20px"
                        name="dollar-alt"
                        fill="#0971CE"
                      ></unicon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <span class="font-size-sm"
                        >{{ $t("APPOINTMENTS.add.price") }}
                        {{ getDoctorFees() }} {{ getLocalCurrency() }}</span
                      >
                      <span v-if="getTax() != ''" class="font-size-sm"
                        >{{ $t("APPOINTMENTS.add.tax") }}
                        <span v-if="getDoctorFees() == 0">
                          0 {{ getLocalCurrency() }}</span
                        >
                        <span v-else>
                          {{ ((getTax() / 100) * getDoctorFees()).toFixed(2) }}
                          {{ getLocalCurrency() }}</span
                        >
                      </span>
                      <span v-if="getFee() != ''" class="font-size-sm"
                        >{{ $t("APPOINTMENTS.add.fees") }}
                        <span>{{ getFee() }} {{ getLocalCurrency() }}</span>
                      </span>
                    </v-list-item-content>
                    <v-list-item-action
                      class="text-primary font-weight-boldest font-size-h5 line-height-sm"
                    >
                      {{ totalAmount() }}
                      {{ getLocalCurrency() }}
                    </v-list-item-action>
                  </v-list-item>
                </template>
                <v-card-title class="text-primary  pb-0">
                  {{ $t("APPOINTMENTS.add.pymtOp") }}
                </v-card-title>

                <div v-if="!isPaymentEnabled()">
                  <div
                    class="text-primary"
                    style="display:flex;text-align: center;padding: 10px;font-size: 1.2rem;"
                  >
                    {{ $t("APPOINTMENTS.add.freemsg") }}
                  </div>
                </div>
                <v-card-text class="px-4" v-else>
                  <div v-if="paymentMethods">
                    <v-checkbox v-model="concent" color="primary">
                      <template v-slot:label
                        ><div class="mt-2 font-size-sm text-muted">
                          {{ $t("APPOINTMENTS.add.concent") }}
                        </div></template
                      ></v-checkbox
                    >
                    <div
                      v-if="allowedPaymentMethods.length > 0"
                      style="display:flex; flex-wrap: wrap; width:100%"
                    >
                      <v-list-item
                        v-if="selectedPaymentMethod"
                        class="rounded-lg mb-1"
                      >
                        <v-list-item-avatar size="30" tile>
                          <v-icon color="primary" size="30">
                            {{ selectedPaymentMethod.icon }}
                          </v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title
                            style="font-size:1.1rem"
                            class="text-primary"
                          >
                            {{ getTranslation(selectedPaymentMethod).name }}
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn icon @click="unselectpayment"
                            ><v-icon color="red">mdi-close</v-icon></v-btn
                          >
                        </v-list-item-action>
                      </v-list-item>
                      <template v-else>
                        <div
                          v-for="(item, index) in allowedPaymentMethods"
                          :key="item.code"
                          style="width:100%;display:flex-box;     flex-direction: column;"
                        >
                          <v-list-item
                            @click="openpaymentwidget(item)"
                            class="rounded-lg mb-1"
                          >
                            <v-list-item-avatar size="30" tile>
                              <v-icon color="primary" size="30">
                                {{ item.icon }}
                              </v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title
                                style="font-size:1.1rem"
                                class="text-primary"
                              >
                                {{ getTranslation(item).name }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider
                            class="m-0 p-0"
                            style="display:block"
                            height="1px"
                            width="100%"
                            :key="'d' + index"
                            v-if="index < allowedPaymentMethods.length - 1"
                          />
                        </div>
                      </template>
                    </div>
                    <template v-if="allowedPaymentMethods.length > 0">
                      <PaymentWidget
                        :paymentuid="banktransferudid"
                        :isActive="displaypayment"
                        :payment_method="selectedPaymentMethod"
                        :total="totalAmount()"
                        :currency="getLocalCurrency()"
                        @close="displaypayment = false"
                        @paymentsuccess="postransaction"
                      />
                    </template>
                  </div>
                </v-card-text>
              </div>
              <v-card-actions
                style="display:flex;align-items: start;padding: 8px;"
                class="actionbar"
              >
                <div style="display:flex;justify-content: center;width: 100%;">
                  <v-btn
                    large
                    width="45%"
                    rounded
                    elevation="2"
                    color="blue darken-1"
                    text
                    class="mx-1"
                    @click="close()"
                  >
                    {{ $t("APPOINTMENTS.add.cancel") }}
                  </v-btn>
                  <v-btn
                    class="mx-1 white--text"
                    large
                    width="45%"
                    color="primary"
                    rounded
                    @click="book()"
                    :disabled="disableBook() || bookingStarted"
                  >
                    {{ $t("APPOINTMENTS.add.book") }}
                  </v-btn>
                </div>
              </v-card-actions>
            </v-card>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card-text>
    <v-dialog v-model="bookingStarted" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          {{ $t("APPOINTMENTS.add.booking") }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showCloseAlert" max-width="290" hide-overlay>
      <v-card>
        <v-card-title class="headline">
          {{ $t("APPOINTMENTS.add.cancelalert.title") }}
        </v-card-title>

        <v-card-text>
          {{ $t("APPOINTMENTS.add.cancelalert.body") }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary darken-1" text @click="showCloseAlert = false">
            {{ $t("APPOINTMENTS.add.cancelalert.continue") }}
          </v-btn>

          <v-btn
            color="red darken-1"
            text
            @click="
              showCloseAlert = false;
              close();
            "
          >
            {{ $t("APPOINTMENTS.add.cancelalert.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showconcentwarning" max-width="290" hide-overlay>
      <v-card>
        <v-card-title class="headline">
          {{ $t("APPOINTMENTS.add.concentalert.title") }}
        </v-card-title>

        <v-card-text>
          {{ $t("APPOINTMENTS.add.concentalert.body") }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="showconcentwarning = false">
            {{ $t("APPOINTMENTS.add.concentalert.close") }}
          </v-btn>

          <v-btn
            color="primary darken-1"
            text
            @click="(showconcentwarning = false), (concent = true)"
          >
            {{ $t("APPOINTMENTS.add.concentalert.agree") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import ApiService from "@/core/services/api.service";
import NotificationService from "@/core/services/notification.service";
import DepartmentList from "./add_comp/DepartList.vue";
import DoctorList from "./add_comp/DocList.vue";
import DateTimeSelect from "./add_comp/DateTimeSelect.vue";
import ShortUniqueId from "short-unique-id";
import PaymentWidget from "./add_comp/paymentwidget.vue";

export default {
  name: "Add",
  props: ["dPreSelection"],
  components: { DepartmentList, DoctorList, DateTimeSelect, PaymentWidget },
  data() {
    return {
      error: "",
      e6: 1,
      departments: null,
      doctorsList: null,
      bookingStarted: false,
      steptitle: this.$t("APPOINTMENTS.add.selectDeprt"),
      showCloseAlert: false,
      showdetails: false,
      banktransferudid: "",
      allowedPaymentMethods: [],
      concent: false,
      displaypayment: false,
      paymentsuccess: false,
      transactionpos: {},
      showconcentwarning: false,
      //appointment details to send
      appointment: {},
      appointementType: "",
      selectedDoctor: null,
      selectedDepartment: null,
      selectedDate: new Date().toISOString().substr(0, 10),
      selectedTime: null,
      paymentMethods: null,
      selectedPaymentMethod: 0,
      total: 0,
    };
  },

  mounted() {
    //console.log("mf is: ", this.$root.mf);
    //this.getDepartments();

    if (this.isPaymentEnabled()) {
      this.getExchangeRate();

      this.getPaymentMethods();
      const uid = new ShortUniqueId({ length: 6 });

      this.banktransferudid = uid();
      console.log(this.banktransferudid);
      console.log("patient", this.$root.patient);
      if (this.$root.patient.selectedDepartment != null) {
        this.selectDepartment(this.$root.patient.selectedDepartment);
      }
    }
    
  },
  watch: {
    "$root.patient": {
      handler(old, val) {
        console.log("it changed", val.selectedDepartment, "was", old);
        if (val != null) {
          this.selectDepartment(val.selectedDepartment);
        }
      },
      deep: true,
    },

    e6: function(newstep) {
      if (newstep == 4) {
        this.setallowedPaymentMethods();
      }
    },
  },
  methods: {
    unselectpayment() {
      //this.$refs.pw.$destroy();
      this.displaypayment = false;
      this.selectedPaymentMethod = 0;
    },
    openpaymentwidget(item) {
      if (this.concent) {
        this.selectpaymentmethod(item);
        this.displaypayment = true;
      } else {
        this.showconcentwarning = true;
      }
    },
    postransaction(e) {
      this.paymentsuccess = true;
      this.transactionpos = e;
      this.book();
    },
    setallowedPaymentMethods() {
      this.allowedPaymentMethods = [];
      var toReturn = [];
      if (this.paymentMethods) {
        this.paymentMethods.filter((i) => {
          i.active_for.filter((j) => {
            if (this.appointementType == j) toReturn.push(i);
          });
        });
      }
      this.allowedPaymentMethods = toReturn;
    },
    selectpaymentmethod(selected) {
      this.selectedPaymentMethod = selected;
    },
    getTranslation(item) {
      if (item.translation.length > 0) {
        for (var i = 0; i < item.translation.length; i++) {
          if (item.translation[i].lang == this.$root.lang) {
            return item.translation[i];
          }
        }
      } else {
        return item;
      }
    },

    navigatetostep(e) {
      this.e6 = e;
    },
    async getPaymentMethods() {
      await ApiService.get(
        "items",
        "payment_methods?fields=*.*&filter[status]=enabled"
      )
        .then(({ data }) => {
          //console.log("payment methods",data.data);
          this.paymentMethods = data.data;
        })
        .catch((error) => {
          this.error = error;
        });
    },
    //move from step one to step two
    selectDepartment(depart) {
      if (depart.doctors.length > 0) {
        this.steptitle = this.$t("APPOINTMENTS.add.selectDoc");
        this.selectedDepartment = depart;
        this.e6 = 2;
      } else {
        console.log("department selected has no doctors yet");
      }
    },
    //move from step two to step three
    async selectDoctor(e) {
      this.steptitle = this.$t("APPOINTMENTS.add.selectTime");
      this.appointementType = e.type;
      this.selectedDoctor = e.doc;
      this.e6 = 3;
    },

    //move from step three to step four
    setSelectedTime(e) {
      this.steptitle = this.$t("APPOINTMENTS.add.pay");
      this.selectedTime = e.time;
      this.selectedDate = e.date;
      this.e6 = 4;
    },
    getTimeZone() {
      return localStorage.timezone;
    },
    getCurrency() {
      return JSON.parse(localStorage.SETTINGS).currency;
    },
    getTax() {
      return JSON.parse(localStorage.SETTINGS).tax;
    },
    getFee() {
      return (
        JSON.parse(localStorage.SETTINGS).handling_fee * this.$root.exchangerate
      ).toFixed(2);
    },
    getLocalCurrency() {
      return JSON.parse(localStorage.geoData).currencySymbolNative;
    },
    getLocalCurrencyCode() {
      return JSON.parse(localStorage.geoData).currencyCode;
    },
    async getExchangeRate() {
      this.$api
        .post(
          "custom/currency/rate/GBP/" +
            JSON.parse(localStorage.geoData).currencyCode
        )
        .then((data) => {
          console.log("rate", data.data);
          this.$root.exchangerate = data.data.data.rate.toFixed(2);
        });
    },
    disableBook() {
      var toReturn = false;
      if (!this.concent) {
        return true;
      }
      if (this.selectedPaymentMethod == 0 || this.paymentMethods == null) {
        toReturn = true;
        return toReturn;
      }
      if (this.selectedPaymentMethod.id == 5 && !this.paymentsuccess) {
        toReturn = true;
        return toReturn;
      }
      return toReturn;
    },
    totalAmount() {
      this.total =
        parseFloat(this.getDoctorFees()) +
        parseFloat((this.getTax() / 100) * this.getDoctorFees()) +
        parseFloat(this.getFee());
      return this.total.toFixed(2);
    },
    getDoctorFees() {
      //console.log("appointment type: ", this.appointementType);
      //console.log("doctor", this.selectedDoctor);
      if (this.appointementType == "video") {
        if (
          this.selectedDoctor.video == "" ||
          this.selectedDoctor.video == null
        )
          return 0;
        else
          return (this.selectedDoctor.video * this.$root.exchangerate).toFixed(
            2
          );
      } else if (this.appointementType == "clinic") {
        if (
          this.selectedDoctor.clinic == "" ||
          this.selectedDoctor.clinic == null
        )
          return 0;
        else
          return (this.selectedDoctor.clinic * this.$root.exchangerate).toFixed(
            2
          );
      } else {
        return 0;
      }
    },

    isPaymentEnabled() {
      return JSON.parse(localStorage.SETTINGS).payment;
    },
    async book() {
      this.$moment.locale("en");
      this.bookingStarted = true;
      this.appointment["type"] = this.appointementType;
      this.appointment["doctor"] = this.selectedDoctor.id;
      this.appointment["medical_file"] = this.$root.mf;
      this.appointment["time"] = this.$moment
        .tz(this.selectedDate + " " + this.selectedTime, "UTC")
        .format("HH:mm:ss")
        .toString();
      this.appointment["date"] = this.selectedDate;
      this.appointment["date_time"] = this.$moment
        .tz(this.selectedDate + " " + this.selectedTime, "UTC")
        .toString();
      this.appointment["status"] = "pending";
      if (this.appointementType == "video") {
        this.appointment["video_room_id"] = btoa(
          this.$root.mf +
            " " +
            this.selectedDoctor.id +
            this.appointment["date_time"]
        );
      }
      this.$moment.locale(localStorage.language);
      var paymentObject = null;
      if (
        this.selectedPaymentMethod.id == 1 ||
        this.selectedPaymentMethod.id == 2
      ) {
        paymentObject = {
          amount: (this.total / this.$root.exchangerate).toFixed(2),
          method: this.selectedPaymentMethod.id,
          udid: this.banktransferudid,
          status: "pending",
        };
      } else if (this.selectedPaymentMethod.id == 5) {
        paymentObject = {
          amount: (this.total / this.$root.exchangerate).toFixed(2),
          method: this.selectedPaymentMethod.id,
          udid: this.banktransferudid,
          status: "confirmed",
          pos_details: this.transactionpos,
        };
      }

      if (paymentObject != null) {
        await ApiService.post("items/payment", paymentObject)
          .then(({ data }) => {
            this.appointment["payment"] = data.data.id;
          })
          .catch((error) => {
            this.error = error;
          });
      }
      console.log("payment id is", this.appointment["payment"]);
      await ApiService.post("items/appointments", this.appointment)
        .then(({ data }) => {
          if (data.data) {
            console.log("sening push");
            NotificationService.notifyByWebPush(
              "New appointment",
              this.appointment["type"] +
                " has been booked by " +
                JSON.parse(localStorage.userInfo).first_name,
              this.selectedDoctor.doctor_id
            )
              .then(({ dpushata }) => {
                console.log("push result", dpushata);
              })
              .catch((error) => {
                this.error = error;
              });
            console.log("sending mail");
            NotificationService.notifyByMail(
              "New appointment",
              this.appointment["type"] +
                " has been booked by " +
                JSON.parse(localStorage.userInfo).first_name,
              this.selectedDoctor.doctor_id
            )
              .then(({ maildata }) => {
                console.log("mail result", maildata);
              })
              .catch((error) => {
                this.error = error;
              });
            //console.log("sending sms");
            /*NotificationService.notifyBySMS(
              "New appointment",
              this.appointment["type"] +
                " has been booked by " +
                JSON.parse(localStorage.userInfo).first_name,
              this.selectedDoctor.doctor_id
            ).then(({ smsresult }) => {
                console.log("sms result", smsresult);
              })
              .catch((error) => {
                this.error = error;
              });;*/

            this.$emit("addnewapp", true);
            this.close();
          }
        })
        .catch((error) => {
          this.error = error;
        });
      this.bookingStarted = false;
    },
    backHandler() {
      if (this.e6 > 1) {
        this.e6--;
      } else {
        if (
          this.selectedDoctor ||
          this.selectedDepartment ||
          this.selectedDate ||
          this.selectedTime ||
          this.selectedPaymentMethod
        ) {
          this.showCloseAlert = true;
        } else {
          this.close();
        }
      }
    },
    close() {
      this.e6 = 1;

      this.steptitle = this.$t("APPOINTMENTS.add.selectDeprt");
      //appointment details to send
      this.appointment = {};
      this.appointementType = "";
      this.selectedDoctor = null;
      this.selectedDepartment = null;
      this.selectedDate = new Date().toISOString().substr(0, 10);
      this.selectedTime = null;
      this.selectedPaymentMethod = 0;
      this.total = 0;
      this.bookingStarted = false;
      this.concent = false;
      this.displaypayment = false;
      this.paymentsuccess = false;
      this.transactionpos = {};
      this.showconcentwarning = false;
      this.$emit("closeClicked", false);
    },

    backAndToast(msg) {
      this.toast("b-toaster-top-center", "success", msg);
      this.step1sub = this.$t("MF.addNew.step1sub");
      this.e6 = 1;
    },
    toast(toaster, v, msg, append = false) {
      this.counter++;
      this.$bvToast.toast(msg, {
        title: `Alert`,
        toaster: toaster,
        solid: true,
        variant: v,
        appendToast: append,
      });
    },
  },
};
</script>
<style scoped>
.v-picker__actions {
  padding-left: 0px !important;
}
</style>
<style>
.v-stepper__wrapper {
  min-height: inherit !important;
}

.v-btn:not(.v-btn--text):not(.v-btn--outlined):hover:before {
  opacity: 0;
}
.theme--light.v-list-item:hover::before {
  opacity: 0;
}
</style>
