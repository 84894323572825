<template>
  <v-bottom-sheet v-if="$root.mobile" v-model="paymentbs">
    <v-card elevation="0" :disabled="disablepage">
      <v-progress-linear indeterminate v-if="disablepage"></v-progress-linear>
      <v-toolbar elevation="0">
        <v-toolbar-title class="text-primary">{{
          getTranslation(payment_method).name
        }}</v-toolbar-title>
        <v-spacer />
        <v-btn icon dark :ripple="false" color="primary" @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <div
          style="margin-top:20px;text-align: center;display:flex;min-height: 20vh;flex-direction:column;justify-content: center;align-items: center;font-size: 1.2rem;"
          v-if="payment_method.code == 'BNK'"
        >
          <div class="text-primary font-weight-boldest font-size-h3">
            {{ $t("PAYMENT.BANK.title") }}
          </div>
          <div class="text-muted mb-5">
            <div
              v-for="(method, m) in payment_method.bank_details.data"
              :key="m"
            >
              <v-list-item style="height:40px">
                <v-list-item-content>
                  {{ $t("PAYMENT.BANK.bankname") }}
                </v-list-item-content>
                <v-list-item-action>
                  {{ method.bank_name }}
                </v-list-item-action>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  {{ $t("PAYMENT.BANK.accountname") }}
                </v-list-item-content>
                <v-list-item-action>
                  {{ method.account_name }}
                </v-list-item-action>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  {{ $t("PAYMENT.BANK.accountdetails") }}
                </v-list-item-content>
                <v-list-item-action>
                  {{ method.iban }}
                </v-list-item-action>
              </v-list-item>
            </div>
          </div>
          <div class="text-muted mb-5">
            {{ $t("PAYMENT.BANK.NOTE1") }}
          </div>
          <div class="text-primary font-weight-boldest font-size-h3">
            {{ paymentuid }}
          </div>
          <div class="mt-5 font-size-sm text-muted">
            {{ $t("PAYMENT.BANK.NOTE2") }}
          </div>
          <v-btn
            block
            color="primary"
            rounded
            class="mt-3"
            dense
            @click="close()"
            >{{
              $t("PAYMENT.BANK.ACTION", {
                total: total,
                currency: localcurrency,
              })
            }}</v-btn
          >
        </div>
        <div style="margin-top:20px;" v-if="payment_method.code == 'POS'">
          <div
            style="display:flex; width:100%; justify-content: center;align-items: center;"
            v-if="!formready"
          >
            <v-progress-circular
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>

          <form v-if="!showsuccess" id="payment-form">
            <input
              type="text"
              v-model="cardholdername"
              placeholder="Card holder"
              style="
                        height: 48px;
                        width: 100%;
                        margin-bottom: 15px;
                        border: 1px solid #d9d9d9;
                        border-radius: 7px;
                        font-weight: 400;
                        font-size: 16px;
                        padding: 0 16px 0 16px;
                    "
            />
            <div id="card-container"></div>
            <v-btn
              block
              id="card-button"
              :disabled="cardholdername.length == 0"
              color="primary"
              rounded
              v-if="formready"
              dense
              @click="handlePaymentMethodSubmission"
              >{{
                $t("PAYMENT.POS.ACTION", {
                  total: total,
                  currency: localcurrency,
                })
              }}</v-btn
            >
          </form>
          <div
            v-else
            style="min-height:176px; width:100%;display:flex;flex-direction:column;justify-content: center;align-items: center;"
          >
            <lottie-animation
              :animationData="require('@/assets/animation/success.json')"
              :loop="false"
              style="height:176px"
            />
          </div>
          <v-snackbar v-model="showerrormsg" timeout="2000">
            <span style="text-danger"> {{ errormsg }}</span>

            <template v-slot:action="{ attrs }">
              <v-btn
                color="white"
                icon
                v-bind="attrs"
                @click="showerrormsg = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
          </v-snackbar>
        </div>
        <div
          style="margin-top:20px;text-align: center;display:flex;min-height: 20vh;flex-direction:column;justify-content: center;align-items: center;font-size: 1.2rem;"
          v-if="payment_method.code == 'CSH'"
        >
          <div class="text-muted mb-5">
            {{ $t("PAYMENT.CASH.NOTE1") }}
          </div>
          <div class="mt-5 font-size-sm text-muted">
            {{ $t("PAYMENT.CASH.NOTE2") }}
          </div>
          <v-btn
            block
            color="primary"
            class="mt-3"
            rounded
            dense
            @click="close()"
          >
            {{
              $t("PAYMENT.CASH.ACTION", {
                total: total,
                currency: localcurrency,
              })
            }}</v-btn
          >
        </div>
      </v-card-text>
    </v-card>
  </v-bottom-sheet>
  <div v-else>
    <div
      style="margin-top:20px;text-align: center;display:flex;min-height: 20vh;flex-direction:column;justify-content: center;align-items: center;font-size: 1.2rem;"
      v-if="payment_method.code == 'BNK'"
    >
      <div class="text-primary font-weight-boldest font-size-h3">
        {{ $t("PAYMENT.BANK.title") }}
      </div>
      <div class="text-muted mb-5">
        <div v-for="(method, m) in payment_method.bank_details.data" :key="m">
          <v-list-item style="height:40px">
            <v-list-item-content>
              {{ $t("PAYMENT.BANK.bankname") }}
            </v-list-item-content>
            <v-list-item-action>
              {{ method.bank_name }}
            </v-list-item-action>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              {{ $t("PAYMENT.BANK.accountname") }}
            </v-list-item-content>
            <v-list-item-action>
              {{ method.account_name }}
            </v-list-item-action>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              {{ $t("PAYMENT.BANK.accountdetails") }}
            </v-list-item-content>
            <v-list-item-action>
              {{ method.iban }}
            </v-list-item-action>
          </v-list-item>
        </div>
      </div>
      <div class="text-muted mb-5">
        {{ $t("PAYMENT.BANK.NOTE1") }}
      </div>
      <div class="text-primary font-weight-boldest font-size-h3">
        {{ paymentuid }}
      </div>
      <div class="mt-5 font-size-sm text-muted">
        {{ $t("PAYMENT.BANK.NOTE2") }}
      </div>
      <v-btn block color="primary" rounded class="mt-3" dense @click="close()">{{
        $t("PAYMENT.BANK.ACTION", {
          total: total,
          currency: localcurrency,
        })
      }}</v-btn>
    </div>
    <div style="margin-top:20px;" v-if="payment_method.code == 'POS'">
      <div
        style="display:flex; width:100%; justify-content: center;align-items: center;"
        v-if="!formready"
      >
        <v-progress-circular
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>

      <form v-if="!showsuccess" id="payment-form">
        <input
          type="text"
          v-model="cardholdername"
          v-if="formready"
          placeholder="Card holder"
          style="
              height: 48px;
              width: 100%;
              margin-bottom: 15px;
              border: 1px solid #d9d9d9;
              border-radius: 7px;
              font-weight: 400;
              font-size: 16px;
              padding: 0 16px 0 16px;
          "
        />
        <div id="card-container"></div>
        <v-btn
          block
          :disabled="cardholdername.length == 0"
          id="card-button"
          color="primary"
          rounded
          v-if="formready"
          dense
          @click="handlePaymentMethodSubmission"
          >{{
            $t("PAYMENT.POS.ACTION", {
              total: total,
              currency: localcurrency,
            })
          }}</v-btn
        >
      </form>
      <div
        v-else
        style="min-height:176px; width:100%;display:flex;flex-direction:column;justify-content: center;align-items: center;"
      >
        <lottie-animation
          :animationData="require('@/assets/animation/success.json')"
          :loop="false"
          style="height:176px"
        />
      </div>
      <v-snackbar v-model="showerrormsg" timeout="2000">
        <span style="text-danger"> {{ errormsg }}</span>

        <template v-slot:action="{ attrs }">
          <v-btn
            color="white"
            icon
            v-bind="attrs"
            @click="showerrormsg = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </div>
    <div
      style="margin-top:20px;text-align: center;display:flex;min-height: 20vh;flex-direction:column;justify-content: center;align-items: center;font-size: 1.2rem;"
      v-if="payment_method.code == 'CSH'"
    >
      <div class="text-muted mb-5">
        {{ $t("PAYMENT.CASH.NOTE1") }}
      </div>
      <div class="mt-5 font-size-sm text-muted">
        {{ $t("PAYMENT.CASH.NOTE2") }}
      </div>
      <v-btn block color="primary" class="mt-3" rounded dense @click="close()">
        {{
          $t("PAYMENT.CASH.ACTION", {
            total: total,
            currency: localcurrency,
          })
        }}</v-btn
      >
    </div>
  </div>
</template>

<script>
import ShortUniqueId from "short-unique-id";
import LottieAnimation from "lottie-web-vue";

export default {
  name: "PaymentWidget",
  props: ["paymentuid", "isActive", "payment_method", "total", "currency"],
  components: {
    LottieAnimation,
  },
  data() {
    return {
      card: null,
      payments: null,
      islaoding: false,
      paymentbs: false,
      formready: false,
      disablepage: false,
      paymentstatus: "",
      errormsg: "",
      showsuccess: false,
      showerrormsg: false,
      localcurrency: "",
      cardholdername: "",
    };
  },
  computed: {},
  watch: {
    isActive: function(flag) {
      if (flag) {
        this.open();
      } else {
        this.close();
      }
    },
    errormsg: function(e) {
      if (e != "") {
        this.showerrormsg = true;
      }
    },
    showerrormsg: function(flag) {
      if (!flag) this.errormsg = "";
    },
  },
  mounted() {
    this.localcurrency = JSON.parse(localStorage.geoData).currencySymbolNative;
    console.log("total is", this.total);
    console.log("currency is", this.currency);
    console.log("local currency", this.localcurrency);
  },

  methods: {
    getTranslation(item) {
      if (item) {
        if (item.translation.length > 0) {
          for (var i = 0; i < item.translation.length; i++) {
            if (item.translation[i].lang == this.$root.lang) {
              return item.translation[i];
            }
          }
        } else {
          return item;
        }
      }
      return "";
    },
    open() {
      if (this.payment_method.code == "POS") this.perpareform();
      this.paymentbs = true;
    },
    close() {
      if (this.card != null) this.card.destroy();
      this.disablepage = false;
      this.showsuccess = false;
      this.showerrormsg = false;
      this.$emit("close");
      this.paymentbs = false;
    },
    async initializeCard(payments) {
      this.card = await payments.card();

      await this.card.attach("#card-container");
      this.formready = true;
      return this.card;
    },
    async perpareform() {
      this.payments = window.Square.payments(
        this.getToken(),
        this.getLocation()
      );
      this.payments.setLocale(this.$root.lang);
      try {
        this.card = await this.initializeCard(this.payments);
      } catch (e) {
        console.error("Initializing Card failed", e);
        return;
      }
    },
    async handlePaymentMethodSubmission(e) {
      e.preventDefault();

      try {
        // disable the submit button as we await tokenization and make a
        // payment request.
        const token = await this.tokenize(this.card);
        let verificationToken;
        verificationToken = await this.verifyBuyer(token);

        console.log("Verification Token:", verificationToken);
        await this.createPayment(token, verificationToken);
      } catch (e) {
        console.error(e.message);
      }
    },
    async createPayment(token, vtoken) {
      this.disablepage = true;
      var locationId = this.getLocation();
      const body = {
        locationId,
        sourceId: token,
        varificationToken: vtoken,
        uuid: new ShortUniqueId().stamp(32),
        amount: (this.total / this.$root.exchangerate).toFixed(2) * 100,
        fee: 0,
        user_id: JSON.parse(localStorage.userInfo).id,
        live: JSON.parse(localStorage.SETTINGS).payment_env,
      };
      console.log("creating payment");
      await this.$api
        .post("custom/payment/dopayment/" + this.paymentuid, body)
        .then(({ data }) => {
          console.log("payment result", data);
          console.log("response code", data.data.response_code);
          if (data.data.response_code == 200) {
            this.paymentstatus = "success";
            this.disablepage = false;
            this.showsuccess = true;
            this.$emit("paymentsuccess", data.data.square_response);
            setTimeout(() => this.close(), 1500);
          } else if (data.data.response_code == 400) {
            this.paymentstatus = "fail";
            this.disablepage = false;
            this.errormsg = data.data.square_response.errors[0].code;
          }
        })
        .catch((error) => {
          this.error = error;
        });
    },
    getToken() {
      if (JSON.parse(localStorage.SETTINGS).payment_env) {
        return this.$root.stoken_prod;
      } else return this.$root.stoken_test;
    },
    getLocation() {
      if (JSON.parse(localStorage.SETTINGS).payment_env) {
        return this.$root.sloc_prod;
      } else return this.$root.sloc_test;
    },
    async verifyBuyer(token) {
      const verificationDetails = {
        amount: (this.total / this.$root.exchangerate).toFixed(2) * 100 + "",
        /* collected from the buyer */
        billingContact: {
          familyName: this.cardholdername.split(" ")[this.cardholdername.split(" ").length -1],
          givenName: this.cardholdername.split(" ")[0],
          email: JSON.parse(localStorage.userInfo).email,
        },
        currencyCode: "GBP",
        intent: "CHARGE",
      };
      console.log("var details", verificationDetails);

      const verificationResults = await this.payments.verifyBuyer(
        token,
        verificationDetails
      );
      return verificationResults.token;
    },
    async tokenize(paymentMethod) {
      const tokenResult = await paymentMethod.tokenize();
      if (tokenResult.status === "OK") {
        return tokenResult.token;
      } else {
        let errorMessage = `Tokenization failed-status: ${tokenResult.status}`;
        if (tokenResult.errors) {
          errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
        }
        throw new Error(errorMessage);
      }
    },
  },
};
</script>
